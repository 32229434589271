import {AsyncComponent} from '@modifi/code-splitting'
import {FullScreenLoader} from '@modifi/ui'

export default AsyncComponent({
  importThunk: () =>
    import(/* webpackChunkName: "static-page" */ '../../features/landing-pages/email-confirmation'),
  loader: FullScreenLoader,
  componentName: 'EmailConfirmation',
  modules: ['static-page'],
  webpack: [require.resolveWeak('../../features/landing-pages/email-confirmation')],
})
