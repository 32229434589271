import {compose} from 'ramda'
import {appFactory} from '@modifi/app'
import {withResponsiveProvider} from '@modifi/ui'
import {
  withScrollProvider,
  withSuspense,
  withScreenSizeProvider,
  Layout,
} from '@modifi/website-client'
import {hydrateStore} from '@modifi/redux'
import {initPlugins} from './plugins'

import routes from './routes'
import reducers from './reducers'
import CustomRouter from './features/router'

const initActions = []
// eslint-disable-next-line no-underscore-dangle
if (typeof window !== 'undefined' && window?.__INITIAL_STATE__) {
  // eslint-disable-next-line no-underscore-dangle
  initActions.push(hydrateStore(window.__INITIAL_STATE__))
}

const getApp = async (options = {}) => {
  await initPlugins(options?.plugins)

  const hocFunc = options.useSuspense
    ? compose(
        withScrollProvider,
        withResponsiveProvider,
        withSuspense(true),
        withScreenSizeProvider
      )
    : compose(withScrollProvider, withResponsiveProvider, withScreenSizeProvider)

  return appFactory({
    client: 'janus',
    routes,
    reducers,
    layout: Layout,
    initActions,
    customRouter: CustomRouter,
    hoc: hocFunc,
  })
}

export default getApp
