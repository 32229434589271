import {AsyncFeature} from '@modifi/code-splitting'
import {FullScreenLoader} from '@modifi/ui'

export default AsyncFeature({
  importThunk: () =>
    import(/* webpackChunkName: "signup" */ '@modifi/website-client/lib/esm/features/sign-up'),
  loader: FullScreenLoader,
  componentName: 'SignUp',
  modules: ['signup'],
  webpack: [require.resolveWeak('@modifi/website-client/lib/esm/features/sign-up')],
})
