import React, {PureComponent} from 'react'
import {routerPropTypes, routerDefaultProps, DefaultRouter} from '@modifi/router'
import type {InferPropTypes} from '@modifi/typescript-utils'
import PropTypes from 'prop-types'

const propTypes = {
  ...routerPropTypes,
  remoteRoutes: routerPropTypes.routes,
  fetchRoutes: PropTypes.func.isRequired,
}

const defaultProps = {
  ...routerDefaultProps,
  remoteRoutes: [],
}

export type CustomRouterProps = InferPropTypes<typeof propTypes, typeof defaultProps>

class CustomRouter extends PureComponent<CustomRouterProps> {
  static propTypes = propTypes

  static defaultProps = defaultProps

  // using UNSAFE_componentWillMount instead of componentDidMount since it's the only lifecycle called in ssr
  // @see https://reactjs.org/docs/react-component.html#componentwillmount
  UNSAFE_componentWillMount() {
    const {fetchRoutes} = this.props

    fetchRoutes()
  }

  render() {
    const {routes, remoteRoutes, ...restProps} = this.props

    // As part of taking down german locale from website, removal of BF and
    // moving of SF, adding temporary redirect routes to handle requests
    const redirectRoutes = [
      {path: '/de', redirectTo: '/'},
      {path: '/de/*', redirectTo: '/'},

      {path: '*/payments/*', redirectTo: '/products/sellers'},

      {path: '*/trade-platform/shipment-tracker', redirectTo: '/products/shipment-tracker'},

      {path: '/trade-platform', redirectTo: '/products/trade-platform'},
      {path: '/us/trade-platform', redirectTo: '/us/products/trade-platform'},
      {path: '/*-en/trade-platform', redirectTo: '/products/trade-platform'},
      {path: '/mx-es/trade-platform', redirectTo: '/mx-es/products/trade-platform'},
      {path: '/cn/trade-platform', redirectTo: '/cn/products/trade-platform'},
      {path: '/hk-zh-tw/trade-platform', redirectTo: '/hk-zh-tw/products/trade-platform'},
    ]

    if (remoteRoutes!.length === 0) {
      return null
    }
    // @ts-expect-error routes have wrong type
    return <DefaultRouter routes={[...remoteRoutes, ...routes, ...redirectRoutes]} {...restProps} />
  }
}

export default CustomRouter
