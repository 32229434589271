import {connect} from '@modifi/redux'
import {aFetchRoutes} from '@modifi/website-client'

import {selectPreparedRemotePages} from './selectors'
import CustomRouter, {CustomRouterProps} from './CustomRouter'

const mapStateToProps = (state: Record<string, any>): Pick<CustomRouterProps, 'remoteRoutes'> => ({
  remoteRoutes: selectPreparedRemotePages(state),
})

const mapDispatchToProps = {
  fetchRoutes: aFetchRoutes,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomRouter)
