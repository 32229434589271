import {MinimalLayout} from '@modifi/website-client'

import {EmailConfirmation} from '../pages'
import urlConstants from './constants'

const {EMAIL_CONFIRMATION_SUCCESS_URL} = urlConstants

export default [
  {
    path: EMAIL_CONFIRMATION_SUCCESS_URL,
    component: EmailConfirmation,
    layout: MinimalLayout,
  },
]
