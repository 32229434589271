import type {ComponentType} from 'react'
import {InternalPageRoute} from '@modifi/router'
import {removeHolesFromAry} from '@modifi/typescript-utils'
import {createSelector} from 'reselect'
import {selectRoutes, RemoteRouteStruct} from '@modifi/website-client'

import {CmsPage, SignUp, EmailConfirmation, WebsiteCmsPage} from '../../pages'

const resolveRemoteComponent = (
  component:
    | 'WebsiteCmsPage'
    | 'CmsPage'
    | 'About'
    | 'Careers'
    | 'Signup'
    | 'EmailConfirmation'
    | string
): ComponentType<any> => {
  switch (component) {
    case 'WebsiteCmsPage':
      return WebsiteCmsPage
    case 'CmsPage':
      return CmsPage
    case 'Signup':
      return SignUp
    case 'EmailConfirmation':
      return EmailConfirmation
    default:
      throw new Error(`Unknown component: ${component}`)
  }
}

const hasDisabledTopNavigationTransparency = (page: RemoteRouteStruct): boolean => {
  if (!page.component) {
    return true
  }
  if (['Signup', 'CmsPage'].includes(page.component)) {
    return true
  }

  return false
}

export const selectPreparedRemotePages = createSelector(selectRoutes, routes =>
  removeHolesFromAry(
    routes.map((page): InternalPageRoute | undefined => {
      if (!page.component) {
        return undefined
      }
      try {
        return {
          component: resolveRemoteComponent(page.component),
          path: page.path,
          componentProps: {
            pageType: page.pageType,
            pageSlug: page.pageName,
            locale: page.locale,
            region: page.region,
          },
          layoutProps: {
            locale: page.locale,
            region: page.region,
            currentPage: {
              pageType: page.pageType,
              pageName: page.pageName,
              component: page.component,
            },
            seoMeta: page.seo,
            disableTopNavigationTransparency: hasDisabledTopNavigationTransparency(page),
          },
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('could not process page', page)
        return undefined
      }
    })
  )
)
