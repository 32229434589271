import {EntityFactory, Reducer} from '@modifi/redux'
import ms from 'ms'
import {socratesClientFactory, getFormattedLocale} from '@modifi/website-client'

const client = socratesClientFactory()

const {actions, reducer, selectors} = EntityFactory({
  fetchRequest: async (
    state,
    {locale, ...restParams}: {locale: string; pageType: string; pageSlug: string}
  ) => {
    const updatedLocale = getFormattedLocale(locale)
    return client.Page.fetch({...restParams, locale: updatedLocale}).then(
      (res: {data: () => any}) => res.data()
    )
  },
  actionNamePrefix: 'pages/cms-page',
  rootSelector: state => state?.pages?.cmsPage || {},
  cacheKeyFunc: ({
    pageType,
    pageSlug,
    locale,
  }: {
    pageType: string
    pageSlug: string
    locale: string
  }) => `${pageType}|${pageSlug}|${locale}`,
  cacheOptions: {
    ttl: ms('1h'),
    max: 15,
  },
})

export default reducer as Reducer

export const aFetchCmsPage = (pageType: string, pageSlug: string, locale: string): any =>
  actions?.fetch({pageType, pageSlug, locale})

export const selectCmsPageIsLoading = (state: Record<string, any>): boolean => {
  if (selectors.selectEntity(state)) {
    return false
  }
  return selectors.selectIsLoading(state)
}

export const {selectEntity: selectCmsPage, selectIsError: selectCmsPageHasError} = selectors
