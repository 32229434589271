import '@modifi/client-polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import Loadable from 'react-loadable'
import getApp from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const i18nConfig =
  // eslint-disable-next-line no-underscore-dangle
  typeof window !== 'undefined' && window?.__INITIAL_I18N_RESOURCES__
    ? {
        // eslint-disable-next-line no-underscore-dangle
        resources: window?.__INITIAL_I18N_RESOURCES__,
        partialBundledLanguages: true,
      }
    : {}

const config = {
  plugins: {
    i18n: {
      ...i18nConfig,
      react: {
        useSuspense: true,
      },
      lng: 'en-GB',
      fallbackLng: 'en-GB',
    },
  },
  useSuspense: true,
}

getApp(config).then(App => {
  const container = document.getElementById('root')
  // eslint-disable-next-line no-underscore-dangle
  if (container.innerHTML !== '') {
    Loadable.preloadReady().then(() => {
      ReactDOM.hydrate(<App />, document.getElementById('root'))
    })
  } else {
    ReactDOM.render(<App />, document.getElementById('root'))
  }
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()
