import {clientConfig} from '@modifi/client-config'

export default {
  HOME_URL: '/',
  CONTACT_URL: '/company/contact-us',
  BUYER_FINANCE_URL: '/buyer-finance',
  SELLER_FINANCE_URL: '/seller-finance',
  SELLER_FINANCE_URL_EN: '/en-gb/seller-finance',
  SELLER_FINANCE_URL_DE: '/de-de/seller-finance',
  ABOUT_URL: '/about',
  CAREERS_URL: '/careers',
  CAREERS_SECTION_URL: '/careers/:section',
  LOGIN_URL: clientConfig.get('SERVICE_HUB'),
  SIGN_UP_URL: '/signup',
  IMPRINT_URL: '/imprint',
  PRIVACY_POLICY_URL: '/privacy-policy',
  PRIVACY_STATEMENT_URL: '/privacy-statement',
  TERMS_OF_USE_URL: '/terms-of-use',
  COOKIE_SETTINGS_URL: '#cookie-settings',
  EMAIL_CONFIRMATION_SUCCESS_URL: '/email-confirmation-success/:status',
  BLOG_URL: clientConfig.get('SERVICE_BLOG'),
  FAQ_URL: clientConfig.get('SERVICE_FAQ'),
  DEV_BLOG_URL: clientConfig.get('SERVICE_DEV_BLOG'),
  PARTNERS_URL: '/partners',
}
